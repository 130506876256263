<template>
    <div class="title-bar">
        <h1>{{ title }}</h1>

        <div
            v-if="($route.meta.permission && $route.meta.permission.includes('documents.list') && $helper.userCapability('create',$route)) || ($route.meta.permission && $route.meta.permission.includes('exports.list') && $helper.userCapability('create',$route)) || ($route.meta.permission && $route.meta.permission.includes('view') && $helper.userCapability('update',$route)) || ($route.meta.permission && $route.meta.permission.includes('create') && $helper.userCapability('create',$route))"
            class="title-bar__buttons">
            <!-- Export button -->
            <a v-if="exportBtn" @click="exportPressed" class="export-btn btn">Export</a>
            <!-- Edit button -->
            <a v-if="edit" @click="editPressed" class="submit-btn edit btn btn-primary">Edit</a>
            <!-- Secondary button -->
            <a v-if="secondaryBtn" @click="secondaryPressed" class="ms-2 btn btn-danger secondary-btn">{{
                    secondaryBtnTitle
                }}</a>
            <!-- Third button -->
            <a v-if="thirdBtn" @click="thirdPressed" class="ms-2 btn btn-gray secondary-btn">{{ thirdBtnTitle }}</a>
            <!-- Fourth button -->
            <a v-if="fourthBtn" @click="fourthPressed" class="ms-2 btn btn-gray secondary-btn">{{ fourthBtnTitle }}</a>
            <!-- Fourth button -->
            <a v-if="fifthBtn" @click="fifthPressed" class="ms-2 btn btn-green secondary-btn">{{ fifthBtnTitle }}</a>
            <!-- Fourth button -->
            <a v-if="sixthBtn" @click="sixthPressed" class="ms-2 btn btn-gray secondary-btn">{{ sixthBtnTitle }}</a>
            <!-- Add button -->
            <router-link v-if="addBtn" :to="addUrl" class="add-btn">
                <img src="@/assets/images/icons/plus.svg" alt="plus"/>
            </router-link>
            <!-- Submit button -->
            <a v-if="submitBtn" @click="submitPressed" class="submit-btn ms-2 btn btn-primary">{{ submitBtnTitle }}</a>
        </div>
        <div v-if="$route.name === 'EditProfile'" class="title-bar__buttons">
            <!-- Submit button -->
            <a v-if="submitBtn" @click="submitPressed" class="submit-btn ms-2 btn btn-primary">{{ submitBtnTitle }}</a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TitleBar',
    props: {
        addBtn: Boolean,
        submitBtn: Boolean,
        exportBtn: Boolean,
        edit: Boolean,
        secondaryBtn: Boolean,
        title: {type: String, default: ''},
        addUrl: String,
        submitBtnTitle: String,
        secondaryBtnTitle: String,
        thirdBtn: Boolean,
        thirdBtnTitle: String,
        fourthBtn: Boolean,
        fourthBtnTitle: String,
        fifthBtn: Boolean,
        fifthBtnTitle: String,
        sixthBtn: Boolean,
        sixthBtnTitle: String
    },
    components: {},
    methods: {
        exportPressed() {
            this.$emit('exportPressed')
        },
        submitPressed() {
            this.$emit('submitPressed')
        },
        editPressed() {
            this.$emit('editPressed')
        },
        secondaryPressed() {
            this.$emit('secondaryPressed')
        },
        thirdPressed() {
            this.$emit('thirdPressed')
        },
        fourthPressed() {
            this.$emit('fourthPressed')
        },
        fifthPressed() {
            this.$emit('fifthPressed')
        },
        sixthPressed() {
            this.$emit('sixthPressed')
        },
    },
}
</script>
