import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";

export default {
    methods: {
        create: function (resource, body, title, successMessage = null, successCallback = null, errorCallback = null) {
            if (resource === this.$Files) {
                this.$store.commit('setLoading', true);
            }
            return resource
                .createResource({body})
                .then((response) => {
                    if (resource === this.$Files) {
                        this.$store.commit('setLoading', false);
                    }
                    if (response.status === 201) {
                        this.$helper.showToast(useToast(), Toast, title, successMessage, "success");
                        if (successCallback) {
                            successCallback(response)
                        }
                        return response
                    }
                })
                .catch((error) => {
                    this.$store.commit('setLoading', false);
                    if (error.response) {
                        this.$helper.showToast(useToast(), Toast, error.response.data["hydra:title"], error.response.data["hydra:description"], "danger");
                        if (errorCallback) {
                            errorCallback(error.response)
                        }
                    }
                })
        },
        update: function (resource, id, body, title, successMessage = null, successCallback = null, errorCallback = null) {
            const url = resource.getName();
            return this.updateByUrl(resource, `/${url}/${id}`, body, title, successMessage, successCallback, errorCallback)
        },
        updateByUrl: function (resource, url, body, title, successMessage = null, successCallback = null, errorCallback = null) {
            return resource
                .updateResourceByUrl({url, body})
                .then((response) => {
                    if (response.status === 200) {
                        this.$helper.showToast(useToast(), Toast, title, successMessage, "success");
                        if (successCallback) {
                            successCallback(response)
                        }
                        return response
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.$helper.showToast(useToast(), Toast, error.response.data["hydra:title"], error.response.data["hydra:description"], "danger");
                        if (errorCallback) {
                            errorCallback(error.response)
                        }
                    }
                })
        },
        delete: function (resource, id, title, successMessage = null, successCallback = null, errorCallback = null) {
            const url = resource.getName();
            return this.deleteByUrl(resource, `/${url}/${id}`, title, successMessage, successCallback, errorCallback)
        },
        deleteByUrl: function (resource, url, title, successMessage = null, successCallback = null, errorCallback = null) {
            if (resource === this.$Files) {
                this.$store.commit('setLoading', true);
            }
            return resource
                .deleteResourceByUrl({url})
                .then((response) => {
                    this.$store.commit('setLoading', false);
                    if (response.status === 204) {
                        if (title) {
                            this.$helper.showToast(useToast(), Toast, title, successMessage, "success");
                        }
                        if (successCallback) {
                            successCallback(response)
                        }
                        return response
                    }
                })
                .catch((error) => {
                    this.$store.commit('setLoading', false);
                    if (error.response) {
                        this.$helper.showToast(useToast(), Toast, error.response.data["hydra:title"], error.response.data["hydra:description"], "danger");
                        if (errorCallback) {
                            errorCallback(error.response)
                        }
                    }
                })
        },
    }
}
